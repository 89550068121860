import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { toggleUserFavourite } from 'data/api'
import { getIsAuthenticated } from 'state/session/selectors'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'

import useDebounce from '../useDebounce'
import useIsMounted from '../useIsMounted'

const TOGGLE_DEBOUNCE_DELAY = 250

enum Type {
  Item = 'item',
  User = 'user',
}

type Props = {
  type: Type
  entityId: number
  isFavourite: boolean
  count?: number
}

const useToggleFavourite = ({
  type,
  entityId,
  isFavourite: initialIsFavourite,
  count: initialCount = 0,
}: Props) => {
  const isMounted = useIsMounted()
  const isLoggedIn = useSelector(getIsAuthenticated) || false
  const [isFavourite, setIsFavourite] = useState(initialIsFavourite)
  const favouriteCount = initialCount + Number(isFavourite) - Number(initialIsFavourite)
  const [hasFavouritedChanged, setHasFavouritedChanged] = useState(false)
  const { openAuthModal } = useAuthModal()

  useEffect(() => {
    setIsFavourite(initialIsFavourite)
  }, [initialIsFavourite])

  function signIn() {
    openAuthModal()
  }

  async function toggle(options?: { onSuccess?: (newValue: boolean) => void }) {
    const response = await toggleUserFavourite({ type, entityId })

    if ('errors' in response) return
    if (!isMounted()) return

    setIsFavourite(prevState => {
      const newState = !prevState

      // We pass `newState` instead of `isFavourite` because `isFavourite` can be outdated:
      // https://github.com/vinted/core/pull/80544#issuecomment-1742588674
      // TODO: Disable favouriting an item while the request is in progress.
      // Move this outside of `setIsFavourite` and use `isFavourite` instead of `newState`.
      options?.onSuccess?.(newState)

      return newState
    })

    setHasFavouritedChanged(true)
  }

  const debouncedToggle = useDebounce(toggle, TOGGLE_DEBOUNCE_DELAY)

  return {
    isFavourite,
    favouriteCount,
    toggleFavourite: isLoggedIn ? debouncedToggle : signIn,
    hasFavouritedChanged,
  }
}

useToggleFavourite.Type = Type

export default useToggleFavourite
